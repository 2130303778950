<template>
    <div>
        <v-card>
            <v-card-title>
                <span class="headline text-center justify-center"
                    >Baixa Manual
                </span>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container grid-list-md>
                        <v-layout wrap>
                            <v-flex xs12 sm6 md6>
                                <v-select
                                    required
                                    label="Tipo de Baixa"
                                    :items="lowTypes"
                                    v-model="low.low_type"
                                    item-text="description"
                                    item-value="id"
                                    data-vv-name="low_type"
                                    v-validate="'required:Tipo de Baixa,low_type'"
                                    outlined
                                    :rules="[rules.required]"
                                ></v-select>
                            </v-flex>

                            <v-flex xs12 sm6 md6>
                                <v-text-field
                                    required
                                    label="Observação *"
                                    v-model="low.observation_low"
                                    outlined
                                    :rules="[rules.required]"
                                    data-vv-name="observation_low"

                                    v-validate="'required:Observação,observation_low'"
                                    clear-icon="mdi-close"
                                ></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-col>
                    <v-btn text @click="setCloseDialogManualLow()"
                        >Cancelar</v-btn
                    >
                </v-col>
                <v-col class="text-right">
                    <v-btn
                        text
                        type="submit"
                        right
                        @click="setTotalVinculations"
                        >Confirmar Baixa(s)</v-btn
                    >
                </v-col>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
    name: 'PeriodsConciliationLowDialog',
    computed: {
        ...mapState('periods_conciliation_low_dialog', ['lowTypes']),
        ...mapFields('periods_conciliation_low_dialog', ['low']),
    },
    data: () => ({
        valid: false,
        rules: {
            required: (value) => !!value || 'Campo obrigatório',
        },
    }),
    methods: {
        setTotalVinculations() {
            let valid = this.$refs.form.validate()
            if (valid) {
                this.updateTotalVinculations()
            }
        },
        ...mapActions('periods_conciliation_low_dialog', [
            'updateTotalVinculations',
            'setCloseDialogManualLow',
        ]),
        // ...mapMutations('periods_conciliation_low_dialog', ['setCloseDialogManualLow']),
    },
}
</script>